import { Directive } from '@angular/core';
import { HubIsAdminBaseDirective } from './is-admin-base.directive';

@Directive({
  selector: '[hubIsNotAdmin]',
  standalone: false,
})
export class HubIsNotAdminDirective extends HubIsAdminBaseDirective {
  shouldCreateEmbeededView = (isAdmin: boolean) => !isAdmin;
}
