import { Directive, OnInit, TemplateRef, ViewContainerRef } from '@angular/core';
import { HubDestroyService } from '@core/services/hub-destroy/hub-destroy.service';
import { UserState } from '@core/store/user/user.state';
import { Store } from '@ngxs/store';

@Directive({
  providers: [HubDestroyService],
  standalone: false,
})
export abstract class HubIsAdminBaseDirective implements OnInit {
  abstract shouldCreateEmbeededView: (isAdmin: boolean) => boolean;

  constructor(
    protected templateRef: TemplateRef<unknown>,
    protected store: Store,
    protected viewContainerRef: ViewContainerRef,
    protected hubDestroyService: HubDestroyService,
  ) {}

  ngOnInit() {
    this.store
      .select(UserState.isAdminUser)
      .pipe(this.hubDestroyService.takeUntilDestroy())
      .subscribe((isAdmin) => {
        this.viewContainerRef.clear();
        if (this.shouldCreateEmbeededView(isAdmin)) {
          this.viewContainerRef.createEmbeddedView(this.templateRef);
        }
      });
  }
}
