import { Directive } from '@angular/core';
import { HubIsAdminBaseDirective } from './is-admin-base.directive';

@Directive({
  selector: '[hubIsAdmin]',
  standalone: false,
})
export class HubIsAdminDirective extends HubIsAdminBaseDirective {
  shouldCreateEmbeededView = (isAdmin: boolean) => isAdmin;
}
